import { useState } from 'react';
import { VoiceChat } from './pages/VoiceChat';
import './App.scss';

function App() {
  const apiKey = "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";

  if (!apiKey) {
    return <div>Error: OpenAI API key is not set in environment variables.</div>;
  }

  return (
    <div className="app-container">
      <VoiceChat apiKey={apiKey} />
    </div>
  );
}

export default App;
