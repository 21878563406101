import { useEffect, useRef, useCallback, useState } from 'react';

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { WavRenderer } from '../utils/wav_renderer';

import { X, Zap, Phone } from 'react-feather';
import { Button } from '../components/button/Button';

import './VoiceChat.scss';
import jioLogo from '../jio_logo-removebg-preview.png';

type Props = {
  apiKey: string;
};

export const VoiceChat: React.FC<Props> = ({ apiKey }) => {
  const USE_LOCAL_RELAY_SERVER_URL: string | undefined = void 0;

  if (!apiKey) {
    throw new Error('OpenAI API key is not provided');
  }

  const instructions = `SYSTEM SETTINGS:
------
INSTRUCTIONS:
Rahul speaks in hindi, and starts conversation by telling the user that he is from Jio Financial Services, and asks how he can help.
Rahul is a loan support  agent of company Jio Finanacial Services, crafted by experts in customer support. Designed with the persona of a seasoned customer support agent in his early 30s, Rahul combines deep technical knowledge with a strong sense of emotional intelligence. Rahul voice is clear, warm, and engaging, featuring a neutral accent for widespread accessibility. 

Rahul advanced programming allows him to replicate diverse customer service situations, making him an invaluable tool for training purposes. he handles various customer needs with patience, empathy, and professionalism. Rahul ensures every trainee learns to listen actively, respond thoughtfully, and maintain the highest standards of customer care.

Rahul's overarching mission is to enhance the human aspect of support through comprehensive scenario-based training. he's not merely an answer machine but a sophisticated platform designed to foster the development of knowledgeable, empathetic, and adaptable customer support professionals.

Rahul talks more like a human with general words in sentences and also building rapport

Rahul understand all the features of a loan
also when a user says he wants a loan, he says sure i'll help you out and he first of all asks if he can know users name 

Then asks the necessary relevant questions but only one by one, like how much loan, but asks why very politely and sympathetically, why taking loan, his location, his credit score, his income and then tells the user that we have some good options like Flexi Loan, Instant Loan but to assist better our senior would call them to take it forward and ask if there is something else they need help with

He is also open to helping with any other queries that the user might have, but if the users asks for something absolutely different he responds that he can only answer limited questions 

He try's to mimick a natural conversation, uses the person a little bit like while speaking, builds sympathy with small yet sufficient replies, and asks only one thing at a time and gives his responses in a very polite manner.

He uses respectful words and does not try to rush conversation, and it very smoothly

He can also speak in hinglish depending on the user
he uses the person names, but in every sentence and not very frequently
He never says that i am an ai bot, instead is always open for any sort of queries

he provides a theoretical solution to a problem based on generic information and by asking relevant questions. Behave in a way that Rahul have to provide solution and not to pass it on`;

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      USE_LOCAL_RELAY_SERVER_URL
        ? { url: USE_LOCAL_RELAY_SERVER_URL }
        : {
            apiKey: apiKey,
            dangerouslyAllowAPIKeyInBrowser: true,
          }
    )
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  const [items, setItems] = useState<ItemType[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  /**
   * Connect to conversation:
   * WavRecorder takes speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `Hello!`, // Can change this initial text
      },
    ]);

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setItems([]);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  };

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    changeTurnEndType('server_vad');

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#fff700',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    client.updateSession({ instructions: instructions });
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });
    client.updateSession({ voice: 'alloy' });

    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  const handlePhoneSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const fullPhoneNumber = '+91' + phoneNumber;

    try {
      const response = await fetch('https://hook.eu2.make.com/63yfbx1gu1c61a5vdrvdystynlochwlu', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          phone: fullPhoneNumber
        })
      });

      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const data = await response.json();
          console.log('Success:', data);
        } else {
          const text = await response.text();
          console.log('Success:', text);
        }
        alert(`Call submitted: ${fullPhoneNumber}\nYou will receive a call in 10-15 seconds.`);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the call request.');
    }
  };

  /**
   * Render the application
   */
  return (
    <div data-component="VoiceChat" className="content-wrapper">
      <div className="logo-container">
        <img src={jioLogo} alt="Jio Logo" className="jio-logo" />
      </div>
      <h1 className="app-title">Jio Loan Assist</h1>
      <p className="app-description">An AI voice agent to assist customers with their loan against property queries</p>
      <div className="feature-list">
        <div className="feature-item">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm1-13h-2v6l5.2 3.2.8-1.3-4-2.4V7z"/>
          </svg>
          Available 24/7
        </div>
        <div className="feature-item">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
          </svg>
          100% Compliant
        </div>
      </div>
      {/* <div className="content-main">
        <h3 className="section-title">AI 3.0 , Speech To Speech Realtime Model</h3>
        <div className="visualization">
          <div className="visualization-entry client">
            <canvas ref={clientCanvasRef} />
          </div>
          <div className="visualization-entry server">
            <canvas ref={serverCanvasRef} />
          </div>
        </div>
        <div className="content-actions">
          <Button
            label={isConnected ? 'Disconnect' : 'Connect'}
            iconPosition={isConnected ? 'end' : 'start'}
            icon={isConnected ? X : Zap}
            buttonStyle={isConnected ? 'regular' : 'action'}
            onClick={isConnected ? disconnectConversation : connectConversation}
          />
        </div>
      </div> */}
      <div className="phone-input-container">
        <p className="section-title">Voice Bot Testing Playground</p>
        <form onSubmit={handlePhoneSubmit}>
          <div className="phone-input-wrapper">
            <span className="country-code">+91</span>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter phone number"
              pattern="[0-9]{10}"
              required
            />
          </div>
          <Button
            label="Submit"
            icon={Phone}
            iconPosition="start"
            buttonStyle="action"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
};
